import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import i18n from "../locale";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index"),
    meta: {
      title: "",
    },
  },

  {
    path: "/advertise",
    name: "Advertise",
    component: () => import("../views/AdvertiseIndex.vue"),
    meta: {
      title: "广告列表",
    },
  },
  {
    path: "/advertise-create",
    name: "AdvertiseCreate",
    component: () => import("../views/AdvertiseCreate.vue"),
    meta: {
      title: "发布广告",
    },
  },
  {
    path: "/advertise-detail",
    name: "AdvertiseDetail",
    component: () => import("../views/AdvertiseDetail.vue"),
    meta: {
      title: "广告详情",
    },
  },

  {
    path: "/order-detail",
    name: "OrderDetail",
    component: () => import("../views/order/OrderDetail.vue"),
    meta: {
      title: "订单详情",
    },
  },
  {
    path: "/order-user",
    name: "OrderUser",
    component: () => import("../views/order/UserOrder.vue"),
    meta: {
      title: "订单列表",
    },
  },
  {
    path: "/advertise-user-list",
    name: "AdvertiseUserList",
    component: () => import("../views/advertise-user/AdvertiseList.vue"),
    meta: {
      title: "我的广告",
    },
  },
  {
    path: "/merchant-center",
    name: "MerchantCenter",
    component: () => import("../views/MerchantCenter.vue"),
    meta: {
      title: "商户中心",
    },
  },
  {
    path: "/trader-register",
    name: "TraderRegister",
    component: () => import("../views/TraderRegister.vue"),
    meta: {
      title: "商户注册",
    },
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("../views/protocol.vue"),
    meta: {
      title: "协议",
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = i18n.t(to.meta.title);
  }
  next();
});

export default router;
