import { isAddress } from "ethers/lib/utils";
import { getLanguage, setLanguage } from "../../utils/language";

const state = {
	language: getLanguage(),
	superior: "0x0000000000000000000000000000000000000000",//语言
	chainId: "",
	downloadVisible: window.app !== 'sds_wallet',
	languages: [{ value: "zh-Hant", name: "中文" }, { value: "en", name: "English" }]
};

// getters
const getters = {
	language: state => state.language,
	languages: state => state.languages,
	superior: state => state.superior,
	chainId: state => state.chainId,
	downloadVisible: state => state.downloadVisible,
};

// actions
const actions = {
	setLanguage({ commit }, data) {
		commit("setLanguage", data);
	},
	setSuperior({ commit }, data) {
		if (isAddress(data)) {
			commit('setSuperior', data);
		}
	},
	setChainId({ commit }, data) {
		commit("setChainId", data);
	},
	setDownloadVisible({ commit }, data) {
		commit("setDownloadVisible", data);
	}
};

const mutations = {
	setLanguage(state, data) {
		const lang = state.language === 'en' ? 'zh-Hant' : 'en'
		state.language = lang;
		setLanguage(lang)
	},
	setSuperior(state, data) {
		state.superior = data;
	},
	setChainId(state, data) {
		state.chainId = data;
	},
	setDownloadVisible(state, data) {
		state.downloadVisible = data;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};