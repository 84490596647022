import {SystemSetting} from "../../api/httpApi";

const protocolKey = "protocol"
const protocol = localStorage.getItem(protocolKey) || 0

const state = {
    protocol: protocol,
	coinList: [],
    systemSetting: {
        WebName: "",
        Logo: "",
        ShareDomain: "",
        IosDownload: "",
        AndroidDownload: "",
        EMail: "",
        WeChat: "",
        Telegram: "",
        Twitter: "",
        TencentQQ: "",
        Facebook: "",
        SessionId: "",
    },
    isRegister: false,
};

// getters
const getters = {
    protocol: state => state.protocol,
    coinList: state => state.coinList,
    systemSetting: state => state.systemSetting,
    isRegister: state => state.isRegister,
};

// actions
const actions = {
    setProtocol({ commit }, data) {
        commit("setProtocol", data);
    },
    setCoinList({ commit }, data) {
        commit("setCoinList", data);
    },
    setSystemSetting({ commit }, data) {
        commit("setSystemSetting", data);
    },
    setIsRegister({ commit }, data) {
        commit("setIsRegister", data);
    },
};

const mutations = {
    setProtocol(state, data){
		state.protocol = data;
		localStorage.setItem(protocolKey, data)
	},
    setCoinList(state, data){
		state.coinList = data;
	},
    setSystemSetting(state, data){
		state.systemSetting = data;
	},
    setIsRegister(state, data){
		state.isRegister = data;
	},
};

export default {
    state,
    getters,
    actions,
    mutations
};