<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {getAccounts, getNetVersion, setupNetwork} from "./utils/wallet";

function getUrlParam(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  } else {
    return null;
  }
}

import {mapActions, mapGetters} from "vuex";
import {CoinGet, SystemSetting} from "./api/httpApi";

export default {
  computed: {
    ...mapGetters(["protocol"])
  },
  data() {
    return {
      account: "",
    }
  },
  created() {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(["setSuperior", "setCoinList", "setProtocol", "setSystemSetting"]),
  },
};
</script>

<style lang="less">
@import "./assets/base.css";
@import "./assets/css/common.css";
@import "./assets/css/app.9d982b84.css";
@import "./assets/css/chunk-vendors.a16c4353.css";

#app {
  height: 100%;
  font-family: Open Sans, -apple-system, PingFang SC, sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: #F4F4F4;
  overflow-x: hidden;
}

body {
}

</style>
