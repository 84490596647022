
export const BASE_SCAN_URLS = {
  56: 'https://bscscan.com',
  97: 'https://testnet.bscscan.com',
  31337: 'https://testnet.bscscan.com',
}

export const NETWORK_LIST = [
  {
    chainId: 56,
    name: "BSC",
    token: "0x55d398326f99059fF775485246999027B3197955",
    decimals: 1e18,
    decimalsNum: 18,
  },
  {
    chainId: 1,
    name: "ETH",
    token: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    decimals: 1e6,
    decimalsNum: 6,
  },
  {
    chainId: 0,
    name: "TRC",
    token: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    decimals: 1e6,
    decimalsNum: 6,
  },
]

export const API_HOST = process.env.VUE_APP_API_HOST || window.location.protocol+"//"+window.location.host+"/api"

export const MAX_INT = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"