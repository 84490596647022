import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as filters from "./filters/index"; // 全局过滤器
import i18n from "./locale/index.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

Vue.config.productionTip = false;
/**
 * element
 * */
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// 注册全局实用程序过滤器（register global utility filters）.
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
