import axios from "../utils/axios";

// 配置
export function MerchantInfo(data) {
    return axios({
        url: "/Merchant/Info",
        method: "post",
        data: data
    });
}

// 获取法币
export function CurrencyGet(data) {
    return axios({
        url: "/Currency/Get",
        method: "post",
        data: data
    });
}

// 获取支付方式
export function PaymentGet(data) {
    return axios({
        url: "/Payment/Get",
        method: "post",
        data: data
    });
}

// 获取支持的币种
export function CoinGet(data) {
    return axios({
        url: "/Coin/Get",
        method: "post",
        data: data
    });
}

// 发布广告
export function ReleaseAdvertise(data) {
    return axios({
        url: "/Release/Advertise",
        method: "post",
        data: data
    });
}

// 获取自己发布广告
export function MerchantAdvertiseGet(data) {
    return axios({
        url: "/Merchant/Advertise/Get",
        method: "post",
        data: data
    });
}

// 获取广告
export function AdvertiseGet(data) {
    return axios({
        url: "/Advertise/Get",
        method: "post",
        data: data
    });
}

// 获取广告详情
export function AdvertiseDetails(data) {
    return axios({
        url: "/Advertise/Details",
        method: "post",
        data: data
    });
}

// 提交订单
export function OrderSubmit(data) {
    return axios({
        url: "/Order/Submit",
        method: "post",
        data: data
    });
}

// 查询订单
export function OrderDetails(data) {
    return axios({
        url: "/Order/Details",
        method: "post",
        data: data
    });
}

// 商户确认收款，用户买单
export function MerchantTransfer(data) {
    return axios({
        url: "/Merchant/Transfer",
        method: "post",
        data: data
    });
}

// 商户确认付款，用户卖单
export function MerchantCollection(data) {
    return axios({
        url: "/Merchant/Collection",
        method: "post",
        data: data
    });
}

// 用户买币 已付款/取消订单
export function MembersCollection(data) {
    return axios({
        url: "/Members/Collection",
        method: "post",
        data: data
    });
}

// 用户确认订单（收款），用户卖单
export function MembersTransfer(data) {
    return axios({
        url: "/Members/Transfer",
        method: "post",
        data: data
    });
}

// 商户取消订单
export function MerchantCancelOrder(data) {
    return axios({
        url: "/Merchant/Cancel/Order",
        method: "post",
        data: data
    });
}

// 用户取消订单
export function MemberCancelOrder(data) {
    return axios({
        url: "/Member/Cancel/Order",
        method: "post",
        data: data
    });
}

// 商户获取我的订单列表
export function MerchantOrderExt(data) {
    return axios({
        url: "/Merchant/Order/Ext",
        method: "post",
        data: data
    });
}

// 用户获取我的订单列表
export function MembersOrderExt(data) {
    return axios({
        url: "/Members/Order/Ext",
        method: "post",
        data: data
    });
}

// 获取配置
export function SystemSetting(data) {
    return axios({
        url: "/System/Setting",
        method: "post",
        data: data
    });
}

// 商户注册
export function MerchantRegister(data) {
    return axios({
        url: "/Merchant/Register",
        method: "post",
        data: data
    });
}

// 注册说明
export function RegisterExplain(data) {
    return axios({
        url: "/Register/Explain",
        method: "post",
        data: data
    });
}

// 订单说明
export function OrderExplain(data) {
    return axios({
        url: "/Order/Explain",
        method: "post",
        data: data
    });
}

// 广告说明
export function AdExplain(data) {
    return axios({
        url: "/Ad/Explain",
        method: "post",
        data: data
    });
}

// 商户注册协议
export function MerchantExplain(data) {
    return axios({
        url: "/Merchant/Explain",
        method: "post",
        data: data
    });
}

// 用户申诉订单
export function MemberComplaintOrder(data) {
    return axios({
        url: "/Member/Complaint/Order",
        method: "post",
        data: data
    });
}

// 商户申诉订单
export function MerchantComplaintOrder(data) {
    return axios({
        url: "/Merchant/Complaint/Order",
        method: "post",
        data: data
    });
}

// 商户修改信息
export function MerchantUpdateInfo(data) {
    return axios({
        url: "/Merchant/Update/Info",
        method: "post",
        data: data
    });
}

// 商户修改信息
export function SendPost(data) {
    return axios({
        url: "/send",
        method: "post",
        data: data
    });
}

